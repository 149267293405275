import React, { Fragment, Component } from 'react';
import {
 BrowserRouter as Router,
 Route,
 Link
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonGroup } from '@material-ui/core';
import SvgIconColor from './IconSvg'
import Hidden from '@material-ui/core/Hidden';

import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
     // backgroundColor:'#ffe57f',
     background:'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',  //#ffab00', //'linear-gradient(45deg, #8e5721 50%, #b46b34 90%)', //#6de6fc #cff6f0 #ffe57f #8e5721
    // borderBlockColor:'black',
    flexGrow: 1
    },

    header:{
        textDecoration:'none',
        color:'white',
        '&:hover': {
          color:'white',
        }
    },
   
    menuButton: {

      marginRight: theme.spacing(0)
    },

   title: {
        marginLeft: theme.spacing(2),
        width:"auto",
        color:'white',
        textShadow:'2px 2px 4px #000 ',
        [theme.breakpoints.up('sm')]:
                     {
          display: 'flex',  
                      },
        flexGrow:1,
          },
      
  buttons: {
          textDecoration:'none',
          margin :theme.spacing(1), 
          backgroundColor:"white",
          color:"black",
          //textShadow:'2px 2px 4px #fff ',
          transition:'0.3s',

          '&:hover': {
            textDecoration:'none',
            backgroundColor: "black",
            color:"white", 
            //textShadow:'2px 2px 4px #000 ',
            transition:'0.3s',
            transform:"scale(1.2)"
                     },
            },
  sectionMobile: {
              display: 'flex',
              [theme.breakpoints.up('md')]:
                       {
                display: 'none',
                        },
                  },
      
      }
   ));

  export default function WithNavigation() {
      const classes = useStyles();
      
      const handleClick = () =>{
        // console.log('ok')
        return <Link to="/" />
      }

      // Define menu states using Hooks

      const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

      const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

      //menu closer on page

      const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
      };

      // menu opener on current page

      const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
      };

      // Menu creation 

      const mobileMenuId = 'primary-menu-mobile';
      const renderMobileMenu = (
      
        // Define the menu operation

        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
              
            <MenuItem className={classes.buttons} component={Link} exact="tue" to="/" >
             <p>Home</p> 
            </MenuItem>
            <MenuItem className={classes.buttons} component={Link} to="/about">
              <p>About us</p>
            </MenuItem>
            <MenuItem className={classes.buttons} component={Link} to="/contactus">
              <p>Contact Us</p>
            </MenuItem>
            <MenuItem className={classes.buttons} component={Link} to="/career">
              <p>Careers</p>
            </MenuItem>
        </Menu>
      
      );
    


      return(
          <div className={classes.root}>
            <AppBar position="fixed" className={classes.root} >
              <Toolbar >

                {/* <IconButton edge='start' className={classes.menuButton} color="inherit" aria-level="menu">   </IconButton> */}
                   {/* < SvgIconColor size="large" /> */}
                  {/* <LinearIndeterminate variant="query">okok</LinearIndeterminate> */}
                    <Typography variant="h6" component="h1" className={classes.title}  align={'left'}   >
                    <Link exact="true" to="/" color='inherit' underline="none" varient="inherit" className={classes.header}>
                        ABCONSOL PROJECTS PRIVATE LIMITED
                    </Link>
                   </Typography>
                
             
              <Hidden smDown>
                          {/* <ButtonGroup  >  */}
                          <Link exact="true" to="/" style={{textDecoration:'none'}}>
                                <Button  variant="contained" className={classes.buttons} >
                                           Home
                                    </Button> 
                                    </ Link>
                                           <Link to="/about" style={{textDecoration:'none'}}>
                                      <Button  variant="contained" className={classes.buttons}>
                                          About us
                                      </Button>
                                      </Link>
                                      <Link to="/contactus" style={{textDecoration:'none'}}>
                                             <Button  variant="contained" className={classes.buttons}  >
                                            Contact Us
                                      </Button>
                                      </Link>
                                      <Link to="/career" style={{textDecoration:'none'}}>
                                     <Button  variant="contained" className={classes.buttons}  >
                                         Careers
                                      </Button>
                                      </Link>
                              {/* </ButtonGroup> */}
                          </Hidden>
                  
          <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MenuIcon />
              </IconButton>
          </div>
                </Toolbar>
             </AppBar>
                {renderMobileMenu}
          </div>
        )
  }
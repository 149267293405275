import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {List, ListItemIcon, ListItem} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import transitions from '@material-ui/core/styles/transitions';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    root: {
        //background: 'linear-gradient(25deg, #000000  25%,#f5f5f5 25%)',
        position:'fixed',
        
       // height: theme.spacing(100),
       // transform:'translate(10px, 250px)',
        // paddingLeft:100,
        top:theme.spacing(25),
        zIndex:100,
        // right:theme.spacing(0),

        '& > *':{
        left: theme.spacing(-2),
        },
    },
    buttons:{
            background:'#fb8c00',
            transform:'scale(0.6)',
            transitions:'0.5s',
        '&:hover': {
            background:'#000000',
            transform:'scale(0.9)',
            transitions:'1.5s',
         }, 
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />
}


export default function FloatingactionButton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Hidden mdDown>
            <List>
                <ListItemLink target="_blank" href="https://www.facebook.com/ABconsol-Projects-Private-Limited-101727598840623/?ti=as">
                    <ListItemIcon>
                        <Fab aria-label='add' className={classes.buttons}>
                            <FacebookIcon style={{color:'#ffffff', transform:'scale(1.3)'}} />
                        </Fab>
                    </ListItemIcon>
                </ListItemLink>
                <ListItemLink target="_blank" href="https://instagram.com/abconsol?utm_medium=copy_link">
                    <ListItemIcon>
                        <Fab aria-label='add' className={classes.buttons}>
                            <InstagramIcon style={{color:'#ffffff', transform:'scale(1.3)'}}/>
                        </Fab>
                    </ListItemIcon>
                </ListItemLink>
                <ListItemLink target="_blank" href="https://wa.me/918480306236">
                    <ListItemIcon>
                        <Fab aria-label='add' className={classes.buttons}>
                            <WhatsAppIcon style={{color:'#ffffff', transform:'scale(1.3)'}} />
                        </Fab>
                    </ListItemIcon>
                </ListItemLink>
                <ListItemLink target="_blank" href="https://www.linkedin.com/in/ab-consol-1a8046219">
                    <ListItemIcon>
                        <Fab aria-label='add'  className={classes.buttons}>
                            <LinkedInIcon style={{color:'#ffffff', transform:'scale(1.3)' }} />
                        </Fab>
                    </ListItemIcon>
                </ListItemLink>
            </List>
           
            </Hidden>
        </div>
    )
}



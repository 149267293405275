import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    root: {
        display:'flex',
        
    },
    media:{
        marginHeight:0,
        marginWidth:0,
        width:1200,
        height:1900,
        // minWidth:200,
        // minHeight:200,
    },
    media2:{
        // marginHeight:0,
        // marginWidth:0,
        width:'auto',
        height:1600,
        // minWidth:200,
        // minHeight:200,
    }
});



function Gform() {

    const classes = useStyles();

    return (
        <div>
             <Card className={classes.root}>
            <Grid container>
                <Grid item >
                    <Grid container >

                    <Grid item xs={12} sm={12} md={12}>
                    <Box display={{sm:"block", xs:"none",md:"block", lg:"block"}}>
            <CardMedia 
                component="iframe"
                src="https://docs.google.com/forms/d/e/1FAIpQLScgrvUlymJythNgABrRBJdRep6QnqFyKkmzipmXz1sSaHQhrQ/viewform?embedded=true" 
                    className={classes.media}
            >

                 </CardMedia>
            </Box>
         </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box display={{sm:"none", xs:"block",md:"none", lg:"none"}} >
            <CardMedia 

                component="iframe"
                src="https://docs.google.com/forms/d/e/1FAIpQLScgrvUlymJythNgABrRBJdRep6QnqFyKkmzipmXz1sSaHQhrQ/viewform?embedded=true" 
                className={classes.media2}
            >
            </CardMedia>
            </Box>
        </Grid>
        </Grid>
                </Grid>
            </Grid>
        </Card>
        </div>
    )
}

export default Gform;

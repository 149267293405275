// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Typography from '@material-ui/core/Typography';
// 

// import SimpleAccordion from './serviceAccordion';
// 
// 

// const useStyles = makeStyles (
//     {
        
//         root: {
//             background:'black',   //'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',//'linear-gradient(0deg, #29b6f6 3%, #ffe57f 100%)',//'linear-gradient(180deg, #ffc107 35%,#ffffff 30%)',
//             border:'1px solid black',
//             maxwidth:260,
//             transform:'scale(0.9)',
//             border:'1px solid white',
//             // '&:hover': {
//             //         boxShadow:'2px 2px 20px 2px rgba(109,230,252)',
//             // }
//         },
    
//     media: {
//         height: 210,
//         //marginBottom:20,
//         //transform:'scale(1.1)',
//         transition:'0.3s',
//         '&:hover': {
//                 //backgroundColor: "#ffc107",// #fff9c4
//                 transform:"scale(2.13)",
//                 transition:'0.3s',  
//     },
//     },
    
//     text: {
        
//         padding: 5,
//         margin: 5,
//         textAlign:'center',
//         border:'1px solid white',
//         width:260,
//         //transform:'scale(0)',
//         border:'1px solid white',
//         color:'white',
//         //textShadow:'2px 2px 4px #000 ',
//         '&:hover': {
//             //backgroundColor: "#ffc107",// #fff9c4
//             transition:'0.3s',
//             color:'black',
//             background:'white',  
// },
//     },

//     heading: {
//         color:'black',
//         //textShadow:'2px 2px 4px #000 ',
//         border:'1px solid black',
//         margin:5,
//         padding: 5,
//         background:'white',
//         //display:'flex',
//         textAlign:'center',
//         align:'right',
//         //boxShadow:'2px 2px 20px 2px rgba(255,255,255)', //rgba(109,230,252)
        
//     },

//     }
// );

// export default function MediaCard3() {
//     const classes = useStyles();

//     function para(){
//         return (   
//         <div>
//         <List className={classes.heading}>
//             <ListItem >
//                 <ListItemText>
//                  <Typography  variant='subtitle1' display='block' >Water Structure construction</Typography>
//                  </ListItemText>
//                      </ListItem>
//                              <Divider />
//                          <ListItem>
//                           <ListItemText>
//                              <Typography  variant='subtitle1'  display='block' >Dam construction</Typography>
//                                  </ListItemText>
//                               </ListItem>
//                               <Divider />
//                           <ListItem>
//                        <ListItemText>
//                     <Typography variant='subtitle1'  display='block' >Revival of water Reserveware</Typography>
//                 </ListItemText>
//              </ListItem>
//         </List>
//     </div>
//         )
//              }

//     return (
//                 <Card className={classes.root} elevation={6}>
//                     <CardMedia
//                         className={classes.media}
//                         image={icon_card2}
//                     />
//                 <CardContent>
//                         <CardActionArea>
//                               <SimpleAccordion name={<Typography className={classes.text} variant='h6' component='h4'>
//                               </Typography> } desc={para()} />
//                         </CardActionArea>
//                     </CardContent>
//                 </Card>       
//         );
// }



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import icon_card4 from './icon/img/road-mini.png';
import icon_card2 from './../../icon/img/aust.png';
import ServiceMenu from './bookService';

const useStyles = makeStyles((theme) => ({
  root: {
      background: 'white', //'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',
    //maxWidth: 345,
    border:'3px solid white',
    transform:"scale(0.9)",
    borderBottom:'10px solid #ffc107', 
  },
  media: {
    height: 0,
    transition:'0.3s',
    paddingTop: '56.25%', // 16:9
    '&:hover': {
                        //backgroundColor: "#ffc107",// #fff9c4
                        transform:"scale(1.2)",
                        transition:'0.3s',
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      
    }),
  },
  expandOpen: {
    transform: 'scale(1.1)',

  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MediaCard1() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} elevation={10}>
      
      <CardMedia

        

        className={classes.media}
        image={icon_card2}
        title="highways"
      />
     
      <CardHeader
        
        action={
            <IconButton disabled={true}>
            <ExpandMoreIcon style={{
                paddingTop:10,
                color: "white"}} />
            </IconButton>
        }

        title="Water and Irrigation"
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
            style={{     color: "white",
                        border:'1px solid white',
                        background:'#000000',
                        //background:'#b66524',
                        textShadow:'2px 2px 4px #000 ',  
                         }}
                            />
      
      
     {/* <CardContent>
         <Typography variant="body2" color="textSecondary" component="p">
          This impressive paella is a perfect party dish and a fun meal to cook together with your
          guests. Add 1 cup of frozen peas along with the mussels, if you like.
        </Typography>
      </CardContent>
     
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
         {/* <CardActions disableSpacing></CardActions> */}
        
        
          {/* <ExpandMoreIcon /> */}
        
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph>we offer services for:</Typography> */}
          <Typography paragraph style={{textAlign:'left'}}>
              
              Canal from rivers, 
              construct all major, medium and minor irrigation
              Networks consisting of improvement of Lining and embankment of canals.
              Water treatment, distribution , management, water supply system,
             sewage system. We also focus on circular economy and provide unique solutions.
          
          </Typography>
          <ServiceMenu form={
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSes0xDpMp35XOTSMrKKo_hQfFSRHuxi2pyYix1yb5xLB1nBbg/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>} />
         
          {/* <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  );
}


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import icon_card4 from './../../icon/img/road-mini.png';
import ServiceMenu from './bookService';

const useStyles = makeStyles((theme) => ({
  root: {
      background: 'white' ,  //'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',
    //maxWidth: 345,
    border:'3px solid white',
    transform:"scale(0.9)",
    //borderBottom:'20px solid black',
    borderBottom:'10px solid #ffc107',
    //transform:"scale(1.05)",
  },
  media: {
    //height: 0,
    
    paddingTop: '56.25%', // 16:9
    transition:'0.3s',
    '&:hover': {
                transform:"scale(1.2)",
                transition:'0.3s',
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      
    }),
  },
  expandOpen: {
    transform: 'scale(1.1)',

  },
}));

export default function MediaCard1() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} elevation={10} >
      
      <CardMedia

        

        className={classes.media}
        image={icon_card4}
        title="highways"
      />
      
      <CardHeader
        
        action={
            <IconButton disabled={true}>
            <ExpandMoreIcon style={{
                paddingTop:10,
                color: "white"}} />
            </IconButton>
        }

        title="Highways and Roads"
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
            style={{     color: "white",
                        border:'1px solid white',
                        background:'#000000',
                        //background:'#b66524',
                        textShadow:'2px 2px 4px #000 ',   
                         }}
                            />
      
      
     {/* <CardContent>
         <Typography variant="body2" color="textSecondary" component="p">
          This impressive paella is a perfect party dish and a fun meal to cook together with your
          guests. Add 1 cup of frozen peas along with the mussels, if you like.
        </Typography>
      </CardContent>
     
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
         {/* <CardActions disableSpacing></CardActions> */}
        
        
          {/* <ExpandMoreIcon /> */}
        
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph>We offer service for:</Typography> */}
          {/* <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography> */}
           <Typography paragraph style={{textAlign:'left'}}>
                Delivering professional services throughout the project lifecycle. 
                From planning, design and engineering to program and construction
                management and  maintenance of roads and highways.
                We also work for various government, semigovernment and private projects of roads and highway in rural and urbans areas   </Typography>
         <ServiceMenu  form={<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScF-fB6YSxeCPmho8R18zO8GofDcgqRkZmfKSBGeWFKyPE2mQ/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
}/>
        
        </CardContent>
      </Collapse>
    </Card>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Text from './fontcardAnimation';

import {useSpring, animated, config } from 'react-spring';

import Box from '@material-ui/core/Box';


import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

import Img from './../img/target.png';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  root: {
     background: 'linear-gradient(10deg,  #fb8c00 1%, #ffc107 10%)' ,  //   #f9a825          ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
     // display: 'flex',
      //width:'auto',
      //backgroundImage:`url(${Image2})`,
        // border: '1px solid white',
        // flex:'0 0 auto',
        //height:'320px',
      //borderColor:'black',
      //borderRadius:10,
      //margin:20,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',
     // marginTop:'10px',
        transform:'scale(0.97)',
        '& > svg ':
         {
             margin : 0,
             
             color:"black",
         },
        
  },
  details: {
    display:"flex",
    flexDirection:"column",
  },
  content:{
     //borderRadius:50,
    //background: 'linear-gradient(300deg, #29b6f6 20%, #ffe57f 100%)', //
    //margin:130,
   // PaddingLeft:100,
   // paddingTop:180,

    //justify:'center',
    
    //textAlign:'Lef',
    color: 'white',
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    //  width:'300px',
    // height:'320px',        //'620px',
    transform:'scale(1)',
    transform:'scale(0.95)'
    // '&:hover':{
    //       textShadow:'2px 2px 4px #000 ',
    //        transform:'scale(1.1)',
    //        transition:'0.3s',
          //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
   // }
  },
  media: {
   // objectFit:'scale-down',
   // borderLeft:'5px solid white',
   // background:'inherit',
   //justifyContent:'center',
    objectFit:'cover',
   // clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%, 15% 0%)',
     //width:'925px',
    // height:'auto',
    //width:'100%',
    height:'400px',
    //zIndex:"-60",
    //transform:'scale(0.90)',
    },
    text:{
        padding:10,
       // color:'white',
        color:'white',
        //textShadow:'2px 2px 2px #fff ' ,
        
    },
   clipper:{
      backgroundColor:'primary',
      heigth:"50px",
      width:"50px",
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  divide:{
    width:'fit-content',
    // paddingRight:50,
    border: '1px solid white ' ,
    height:620,
    borderRadius: 0,
    //boxShadow:'0px 0px 5px 2px rgba(109,230,252)'
  }

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function MediaCard2() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1.3)', transition:' transform 0.5s',},
    from: {y:-30,z:0, opacity:0 , transform:'scale(1.3)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={10} >
      
      <Grid container spacing={2}>
      <Box display={{sm:"block", xs:"none",md:"block", lg:"block"}}>
       <Grid item xs={12}  sm container>
         
           <Grid item xs={12} sm={12} md={6}>
        
             <Paper elevation={10} style={{   transform:'scale(1)',  
                                          background: '#fb8c00', 
                                                 }}>
        <CardContent className={classes.content}>
          <div>
          <Paper elevation={10} style={{ width:'300px' ,height:'60px', padding:5,background:'#000000',margin:'0px',transform:'translate(0px, -10px)'}} >
                                              <Typography variant="h5" style={{color:'white',padding:6 }}>
                                                  Our Vision
                                              </Typography>
                                              </Paper>
        <Paper  elevation={12} style={{//transform:'scale(1.01)',
                                          display:'flex' }}>
             
           
           <div style={{  padding:15,
                                  transform:'translate(0px, 0px)'
                                   }}>

           <Typography variant="subtitle1" component="h1" style={{padding:10, color:'black',
                                                                                textShadow:'0px 0px 0px #fff ' ,
                                                                                textAlign:'left',
                                                                                paddingLeft:0}}>
             Our dream is to become the leading construction company. A company that our customers want to
              work with and our employees  are proud to work for. we have cultivated a culture in which employees 
              share ideas and dedication, develop innovative ideas and evaluate and implement improvements within the company. 
                Management recognise each stage estimating, tendering and construction as critical components within the risk 
                management model, but  also vital steps toward developing trust with new and existing clients.
                  We create opportunities to achieve something extraordinary, and we reward success.

            
          </Typography>
          </div>
          
          </Paper>
          </div>
        </CardContent>
       
      </Paper>
      </Grid>
     
      <Grid item xs={12} sm={12} md={6} >
     
      <CardMedia
          className={classes.media}
        
          image={Img}
          title="aboutUs-img"
        >
          <div style={{ paddingLeft:70, paddingTop:110}}>
            {/*  transform:'translate(100px, 90px)', */}
          
                                          </div>
          
        </CardMedia> 
        </Grid>
        
      </Grid>
      </Box>
      <Box display={{sm:"block", xs:"block",md:"none", lg:"none"}} >
       <Grid item xs={12}  sm container>
       <Grid item xs={12} sm={12} md={6} >
     
     <CardMedia
         className={classes.media}
       
         image={Img}
         title="aboutUs-img"
       >
         <div style={{ paddingLeft:70, paddingTop:110}}>
           {/*  transform:'translate(100px, 90px)', */}
         
                                         </div>
         
       </CardMedia> 
       </Grid>
           <Grid item xs={12} sm={12} md={6}>
        
             <Paper elevation={10} style={{   transform:'scale(1)',  
                                          background: '#fb8c00', 
                                                 }}>
        <CardContent className={classes.content}>
          <div>
          <Paper elevation={10} style={{ width:'300px' ,height:'60px', padding:5,background:'#000000',margin:'0px',transform:'translate(0px, -10px)'}} >
                                              <Typography variant="h5" style={{color:'white',padding:6 }}>
                                                  Our Vision
                                              </Typography>
                                              </Paper>
        <Paper  elevation={12} style={{//transform:'scale(1.01)',
                                          display:'flex' }}>
             
           
           <div style={{  padding:15,
                                  transform:'translate(0px, 0px)'
                                   }}>

           <Typography variant="subtitle1" component="h1" style={{padding:10, color:'black',
                                                                                textShadow:'0px 0px 0px #fff ' ,
                                                                                textAlign:'left',
                                                                                paddingLeft:0}}>
             Our dream is to become the leading construction company. A company that our customers want to
              work with and our employees  are proud to work for. we have cultivated a culture in which employees 
              share ideas and dedication, develop innovative ideas and evaluate and implement improvements within the company. 
                Management recognise each stage estimating, tendering and construction as critical components within the risk 
                management model, but  also vital steps toward developing trust with new and existing clients.
                  We create opportunities to achieve something extraordinary, and we reward success.

            
          </Typography>
          </div>
          
          </Paper>
          </div>
        </CardContent>
       
      </Paper>
      </Grid>
     
     
        
      </Grid>
      </Box>
    
        </Grid>
    </Card>
  );
}

import React, {useRef} from 'react';
import {styled,  makeStyles} from '@material-ui/core/styles';
import { palette, spacing, compose } from '@material-ui/system';
import CssBaseLine from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import WithNavigation from '../navigation__bar/AppBarWithNavigation';
import ImgMediaCard from './header/frontCard';
import ImgmediaCard2 from './header/ImgmediaCard';
import ImgMediaCardMsg from './body/msg_card/cardMsg';
import ImgMediaCardMsg2 from './body/msg_card/cardMsg1';
import ListTtemLink from '../footer/TableLine';
import MediaCard1 from './body/service_card/cardInfo1';
import MediaCard2 from './body/service_card/CardInfo2';
import MediaCard3 from './body/service_card/CardInfo3';
import MediaCard4 from './body/service_card/CardInfo4';
import Grid from '@material-ui/core/Grid';
//import Text from './fontcardAnimation';
import ServiceInfo from './body/service_card/ServiceBar';

import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import frontImgcard from './header/ImgmediaCard';
import image from './icon/img/architect.jpg';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import FloatingactionButton from '../floatingActionButton/floatingActionbutton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root:{
    flexGrow:1,
    backgroundColor: '#f5f5f5',   //'#ffff8d',       //'linear-gradient(0deg, #6de6fc 19%, #e6ee9c  60%)',  //'linear-gradient(236deg, #ffeb3b 65%, #10d9f8 60%)', #ffeb3b
    //backgroundImage:`url(${image})`,
    width:'auto',
  },
  control:{
    paddingRight:theme.spacing(2),
    paddingLeft:theme.spacing(2),
    marginTop:theme.spacing(9),
  },
}));


const Mox = styled('div')(compose(spacing, palette));

  export default function StyledComponents () {
    const classes = useStyles();

    return(
      <React.Fragment>
      
             
              
                  
              
            
        <CssBaseLine />
       
       
        
        
        <Mox className={classes.root}>
          
        <FloatingactionButton />
        <WithNavigation />
        
        
        <Container maxWidth="xl"  className={classes.control}>
        <React.Fragment>
        
        </React.Fragment>
        {/* <Parallax pages={3} style={{top: '50', left: '100'}}>

          */}
            {/* front page starting with image card */}
            
              
            {/* <ParallaxLayer
                  offset={1}
                  speed={3.5}
                  style={{
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:'yellow',

                  }}>
                  </ParallaxLayer>
                  <ParallaxLayer
                  offset={1}
                  speed={2.5}
                  style={{ backgroundColor:'#29b6f6'}}  />*/}
                     <Grid container spacing={3} > 
                     
                      <Grid item xs={12} > 
                      <Box display={{sm:"none", xs:"none",md:"none", lg:"block"}}>
                    
                       <ImgMediaCard />
                       </Box>
                       </Grid>
                      

                     
                       <Grid item xs={12} > 
                       <Box display={{sm:"block", xs:"block",md:"block", lg:"none"}} >
                       <ImgmediaCard2 />
                       </Box>
                       </Grid>
                       
                 


                  
                    {/* < Text /> */}
                    <Paper elevation={10} spacing={10}  style={{  paddingTop:5,width:'1900px', height:'auto',
                                                     background: 'linear-gradient(0deg,  #fb8c00 43%, #ffffff 10%)',
                                                     transform:'scale(0.97)'
                                                    }}>
                       <Grid container  spacing={3} >
                           <Grid item xs={12} sm={12}>
                        <ServiceInfo  />
                    </Grid>
                    
                  {/* services and description */}
                  
                        <Grid item xs={12} sm={12} md={3}><MediaCard1 /></Grid>  
                        <Grid item xs={12} sm={12} md={3}><MediaCard2 /></Grid>
                        <Grid item xs={12} sm={12} md={3}><MediaCard3 /></Grid>
                        <Grid item xs={12} sm={12} md={3}><MediaCard4 /></Grid>
                       
                     </Grid>
                     </Paper>
                     
                    {/* second service and description */}
                        
                    {/* <Grid item xs={6} sm={9} md={12}>
                                     <ImgMediaCardDesc name='okok' />
                                 </Grid>  */}
                      {/* <ParallaxLayer
                      offset={2.}
                      speed={0.5}
                      style={{
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:'transparent'
    
                      }}
                  >
                    
                       </ParallaxLayer> */}
                                        {/*  message and avattar */}
                                        <Paper elevation={0} style={{  paddingTop:0,
                                                    background: 'linear-gradient(180deg,  #ffffff 48%, #ffc107 10%)',
                                                   transform:'scale(0.97)'
                                                    }}>
                                        <Grid container >
                                       
                                                     
                                 <Grid item xs={12} sm={12} md={6}>
                                     <ImgMediaCardMsg  name="Chairman's  Message"/> 
                                 </Grid>
                                 
                                 
                                 <Grid item xs={12} sm={12} md={6}>
                                       {/*  message of avatar icon and card */}
                                    <ImgMediaCardMsg2 name="Managing director's Message" /> 
                                   </Grid>
                                  
                                   </Grid>
                                   </Paper>
                                   <Grid item xs={12} sm={12} md={12} >
                                        {/* Footer */}
                                          <ListTtemLink />
                                           </Grid>
                                           {/* <Grid item xs={12} sm={12} md={12} >  */}
                                             {/* Footer */}
                                        {/* \<Paper style={{background:'#f5f5f5f5'}}>
                                          <About />
                                          </Paper> 
                                           </Grid> */}

                                        <Grid>
                                
                     
                     </Grid>
                </Grid>
                {/* </Parallax> */}
                </Container>
                {/* <ListTtemLink /> */}
        </Mox>
        
        </React.Fragment>
      );
  }


  
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Text from './fontcardAnimation';

import {useSpring, animated, config } from 'react-spring';

import { Divider, ThemeProvider } from '@material-ui/core';


import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

import {List, ListItemIcon , ListItemText, ListItem} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import  GoogleApiWrapper  from './mapComponent';

import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
// import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles({
  root: {
     background:'#ffffff', // 'linear-gradient(130deg,  #fb8c00 1%, #ffc107 10%)' , //   #f9a825          ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
      //display: 'flex',
      width:'auto',
      transform:'scale(0.98)',
      //backgroundImage:`url(${Image2})`,
        border: '1px solid white',
        //height:'320px',
      //borderColor:'black',
      //borderRadius:10,
      //margin:20,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',
      //marginTop:'0px',
       // transform:'scale(1)',
        '& > svg ':
         {
             margin : 0,
             
             color:"black",
         },
        
  },
  details: {
    display:"flex",
    flexDirection:"column",
  },
  content:{
     //borderRadius:50,
    //background: 'linear-gradient(300deg, #29b6f6 20%, #ffe57f 100%)', //
    paddingTop:400,
   
    
    //justify:'center',
    //flex:'1 0 auto',
    //textAlign:'Lef',
    color: 'white',
    //textShadow:'2px 2px 4px #000 ',
    //transition:'0.3s',

     //width:'800px',
    //height:'600px',        //'620px',
    //transform:'scale(1)',
    //transform:'scale(0.9)'
    // '&:hover':{
    //       textShadow:'2px 2px 4px #000 ',
    //        transform:'scale(1.1)',
    //        transition:'0.3s',
          //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
   // }
  },
  media: {
   // objectFit:'scale-down',
   // borderLeft:'5px solid white',
   // background:'inherit',
    objectFit:'cover',
    //clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%, 15% 0%)',
    // width:'625px',
    // height:'auto',
    //width:'100%',
     height:'610px',
    //zIndex:"-60",
   // transform:'scale(0.9)',
    },
    text:{
        padding:10,
       // color:'white',
        color:'white',
        //textShadow:'2px 2px 2px #fff ' ,
        
    },
   clipper:{
      backgroundColor:'primary',
      heigth:"50px",
      width:"50px",
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  divide:{
    width:'fit-content',
    // paddingRight:50,
    border: '1px solid white ' ,
    height:620,
    borderRadius: 0,
    //boxShadow:'0px 0px 5px 2px rgba(109,230,252)'
  }

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function ImgMediaMapCard() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1.3)', transition:' transform 0.5s',},
    from: {y:-30,z:0, opacity:0 , transform:'scale(1.3)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={15} >
              <Grid container spacing={2}>
       <Grid item xs={12}  sm container>
       <Grid item xs={12} sm={12} md={12} >
       {/* <Hidden mdDown> */}
      <CardContent >
         
          <GoogleApiWrapper />
        </CardContent>
        {/* </Hidden> */}
        </Grid>
        
          <Grid item xs={12} sm={12} md={12} >
      
      {/* <Divider className={classes.divide}  orientation="vertical" /> */}
            
        
            
           {/* <CardContent style={{transform:'scale(1.2)',margin:1,}}>
           <Paper style={{  
                            background:'linear-gradient(0deg, #ef6c00 10%, #ffa726  60%)' ,
                            paddingTop:410,
                            marginTop:15,
                            transform:'scale(0.9)',
                            paddingLeft:10,
                            paddingRight:10,
                            paddingBottom:10 ,
                            // border:'2px solid #ffffff',
                            borderRadius:5,}}>
             <div>
             
            </div>
            </Paper>
        </CardContent> */}
      
        
       
        {/* <Divider orientation="vertical" /> */}
        
       
        
         
        {/* // style={{transform:'scale(0.98)', }}  */}
          
         
        
        <CardContent className={classes.content}>
        <Paper elevation={2} style={{   transform:'scale(1)',
                                        padding:0,
                                           // width:'900px',
                                         // opacity:0.95,
                                          //borderRadius:10, 
                                         //clipPath: 'polygon(100% 0%, 95% 95%, 0% 95%, 0% 0%)',
                                          background: '#fb8c00', //linear-gradient(90deg, #ffffff  1%, #fb8c00  100%)', //'linear-gradient(0deg, #ffffff  60%,#e0e0e0 100%)',
                                         }}>
            <Paper elevation={20  } style={{  height:'60px',
                                              padding:10,
                                              width:'300px' ,
                                              transform:'scale(0.75)',
                                              //transform:'translate(0px, 1px)',
                                               background:'black',
                                        }} 
                                          >
                                              <Typography variant="h5" style={{color:'white',textShadow:'0px 0px 0px #fff ' ,}}>
                                                  Address
                                              </Typography>
                                          </Paper>
        
        <Paper  elevation={15} style={{transform:'scale(0.95)',
                                          
                                          // height:'470px',
                                          // width:'750px' ,
                                          background:'white' ,
                                          borderBottom:'10px solid #000000',
                                         //border:'2px solid #ffffff',
                                        // clipPath: 'circle(61% at 51% 55%)',
                                          //  clipPath: 'polygon(70% 30%, 100% 70%, 0% 70%, 0% 30%)',
                                         // transform:'translate(0px, -170px)',

                                          }}>
           
        
           {/* <animated.div style={props}></animated.div> */}
           
           
           
           {/* <div style={{padding:15,
                                  //background:'linear-gradient(0deg, #fb8c00 10%, #ffffff  10%)'   ,//'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)' , //'black',
                                  transform:'scale(1)',
                                  // borderLeft:'20px solid #f9a825',
                                  // borderBottom:'20px solid #000000',
                                  // borderRadius:50,
                                 // transform:'translate(0px, 0px)'
                                   }}> */}
            <List>
                                    <ListItem>
                                        <ListItemIcon>
                                        <BusinessIcon size='large' />
                                        </ListItemIcon>
                                            <Typography variant='subtitle1'>Office </Typography>
                                    </ListItem>
                                    <Divider />
                                </List>
                               
                                <div className={classes.space}>
                                {/* <Typography variant='body2' gutterBottom> okko </Typography> */}
                                <List>
                                  <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary=" House no - 7,  Harmony Villa,  Lane No - 4,  SBI ATM LANE KANAK VIHAR, PHASE 2 Near PHP watertank, Patia, Bhubaneswar, Pin- 751024 ">
                                  
                                  </ListItemText>
                                  </ListItem>
                                  </List>
                                  </div>
                                  <div  className={classes.position}>
                                                <List  >
                                                <ListItem>
                                                    <ListItemIcon>
                                                    <PhoneIcon size='large' style={{color:'#000000'}} />
                                                    </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography variant='subtitle1' style={{color:'#000000'}} >
                                                                +91 - 84803 06236
                                                                </Typography>
                                                            </ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                    <ListItemIcon>
                                                    <EmailIcon size='large' style={{color:'#000000'}} />
                                                    </ListItemIcon>
                                                        <ListItemText>
                                                                <Typography variant='subtitle1' style={{color:'#000000'}} >
                                                                    info@abconsol.com
                                                                </Typography>
                                                            </ListItemText>
                                                    </ListItem>
                                                    </List>
                                            </div>
          
          
          </Paper>
          </Paper>
        </CardContent>
        
     
      
      {/* <Grid item md={}> */}
     
    
      </Grid>
      
      
        </Grid>
        </Grid>
    </Card>
  );
}

import React, {useRef} from 'react';
import {styled,  makeStyles} from '@material-ui/core/styles';
import { palette, spacing, compose } from '@material-ui/system';
import CssBaseLine from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import WithNavigation from '../navigation__bar/AppBarWithNavigation';
import MediaCard from './header/frontCard';
import MediaCard2 from './body/frontCard2';
import Grid from '@material-ui/core/Grid';
import ListTtemLink from '../footer/TableLine';
import MediaCard3 from './body/frontCard3';

const UseStyles = makeStyles((theme) => ({
  root:{
    flexGrow:1,
    backgroundColor: '#f5f5f5',   //'#ffff8d',       //'linear-gradient(0deg, #6de6fc 19%, #e6ee9c  60%)',  //'linear-gradient(236deg, #ffeb3b 65%, #10d9f8 60%)', #ffeb3b
    //backgroundImage:`url(${image})`,
  },
  control:{
    padding:theme.spacing(1),
    marginTop:theme.spacing(7),
  },
}));


const Box = styled('div')(compose(spacing, palette));

  export default function aboutComponents () {
    const classes = UseStyles();

    return(
      <React.Fragment>
      
             
              
                  
              
            
        <CssBaseLine />
       
       
        
        
        <Box className={classes.root}>
          

        <WithNavigation />
        
        
        <Container maxWidth="xl"  className={classes.control}>
        <Grid container spacing={3} > 
        <Grid item xs={12} md={12} sm={12}> 
                      {/* <frontImgcard /> */}
                      <MediaCard />
                  
                       </Grid>
                       <Grid item xs={12} md={12} sm={12}> 
                      {/* <frontImgcard /> */}
                     
                      <MediaCard2 />
                  
                       </Grid>
                       <Grid item xs={12} md={12} sm={12}> 
                      {/* <frontImgcard /> */}
                     
                      <MediaCard3 />
                  
                       </Grid>
                       <Grid item xs={12} md={12} sm={12}> 
                      {/* <frontImgcard /> */}
                     <ListTtemLink />
                  
                       </Grid>
        </Grid>
       
        </Container>
        </Box>
        
        </React.Fragment>
      );
  }


  
import React, { Component } from 'react'
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react'


export class componentDidUpdate extends Component {
    constructor(props){
        super(props);
        console.log('this is working,')
        
    }
    
    render() {
        return (
            <div>
                <Map
                    google={this.props.google}
                    zoom={10}
                    style={{
                        width: '98%',
                        height:'50%',
                    }
                     }
                    initialCenter={{lat:20.349019, lng:85.828435}}
                >
                    <Marker position={{lat:20.349019, lng:85.828435}} />
                </Map>
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey:'AIzaSyDTJrx-Se7UP66zF0iekUa6buISCu913ZI'
})(componentDidUpdate);

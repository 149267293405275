import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useSpring, animated, loop } from 'react-spring';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    root: {
    // background:`url(${Image})`,
    background:"black", //'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)', //#6de6fc #cff6f0 #ffe57f
    
    // borderBlockColor:'black',
    //color:'black',
    //height:theme.spacing(5),
        
        transform:"scale(0.97)",
        height:'60px',
    border:'3px solid white',
    borderBottom:'5px solid #ffc107',
    //borderTop:'10px solid red',
    //padding:theme.spacing(0),
    //borderRadius:50,
    //display:'flex',
    
    flexGrow: 1,
    //justify:'flex-start'
    //textAlign:'left',
   
    },
    chip:{
        padding:theme.spacing(2),
       // width:theme.spacing(20),
        //height:theme.spacing(3),
        backgroundColor:'inherit',
       color :'white',
        boxShadow:'2px 2px 10px 2px rgba(109,230,252)'
    },
    title: {
        paddingTop:theme.spacing(1),
        marginLeft: theme.spacing(3),
        width:200,

        color:'white',
        //textShadow:'2px 2px 4px # ',
        flexGrow:1,
        
          },
}
));


export default function ServiceInfo() {
    
    const classes = useStyles();

    const styles = useSpring({
        loop: {reverse: true},
        from: {x: -10, },
        to: {x: 10,},
    });

    return (
        <div>
            {/* <AppBar className={classes.root} position='static'></AppBar> */}
            <Paper className={classes.root} elevation={3}>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                <Grid item >
                <animated.div style={styles}>  </animated.div>                  
                        
                        {/* <Chip className={classes.chip} label='Our Services' ></Chip>  */}
                           
                                       <Typography variant="h5"className={classes.title}>Our Services</Typography>
                                       {/* <IconButton>
                                       <ArrowForwardIosIcon style={{alignCo:'left'}} />
                                       </IconButton>
                                 */}
                             
                             
                       
                        
                        </Grid>
                
                     </Grid>
                     </Paper>
        </div>
    )
}


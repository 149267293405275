import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
    BrowserRouter as Router,
    Route,
    Link,
   } from 'react-router-dom';
   
import {List, ListItemIcon , ListItemText, ListItem, Typography} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import CopyrightIcon from '@material-ui/icons/Copyright';
import BrushIcon from '@material-ui/icons/Brush';
import PhoneIcon from '@material-ui/icons/Phone';
import Paper from '@material-ui/core/Paper';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DesignerMenu from './designerMenu';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';



const useStyles = makeStyles ((theme) => ({
    root: {
        
        background:'#eeeeee',
        flexGrow: 1,

    },
    paper:{
    padding: theme.spacing(2),
    margin: '0px',
    width:'auto',
    transform:'scale(0.98)',
    },
    space: {
        
       
    },
    position:{
            padding:theme.spacing(1),
             },
    position2:{
            marginLeft:theme.spacing(0),
             },
}));

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />
}
function ListItemUrl(props) {
    return <ListItem button component="a" {...props} />
}

export default function ListTtemLink() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={10}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm container>
                        <Grid item xs={12} sm={6} md={3} container direction='column' spacing={0}>
                            <Grid item xs style={{justfyContent:'center', alignItems:'center'}}>
                                <List>
                                    <ListItem>
                                        
                                            <Typography gutterBottom variant='subtitle1'>Address</Typography>
                                    </ListItem>
                                    <Divider />
                                </List>
                               
                                <div className={classes.space}>
                                    <List>
                                        <ListItem>
                                        <ListItemIcon>
                                        <BusinessIcon size='large' />
                                        </ListItemIcon>
                                        <ListItemText primary="Office" />
                                        </ListItem>
                                        <ListItem>
                                        <ListItemText>
                                {/* <Typography variant='body2' gutterBottom> okko </Typography> */}
                                <Typography variant='body2' color="textSecondary" style={{textAlign:'left',
                                                                                            //textIndent:'10px',
                                                                                             padding: 20}} >
                                                                                                 
                                        House no - 7 ,
                                        Harmony Villa , 
                                        Lane No - 4  SBI ATM LANE ,
                                        KANAK VIHAR,
                                        PHASE 2 Near PHP watertank,
                                        Patia.
                                        Bhubaneswar,
                                        Pin- 751024
                                  </Typography> 
                                  </ListItemText>
                                  </ListItem>
                                  </List>
                                  </div>
                                  <div  className={classes.position}>
                                                <List  >
                                                <ListItem>
                                                    <ListItemIcon>
                                                    <PhoneIcon size='large' color="inherit" />
                                                    </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography variant='caption' color="textSecondary" >
                                                                +91 - 84803 06236
                                                                </Typography>
                                                            </ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                    <ListItemIcon>
                                                    <EmailIcon size='large' color="inherit" />
                                                    </ListItemIcon>
                                                        <ListItemText>
                                                                <Typography variant='caption' color="textSecondary" >
                                                                    info@abconsol.com
                                                                </Typography>
                                                            </ListItemText>
                                                    </ListItem>
                                                    </List>
                                            </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} >
                            <Grid item xs style={{justfyContent:'center', alignItems:'center'}}>
                            <List>
                                    <ListItem>
                                        {/* <ListItemIcon>
                                        <BusinessIcon size='large' />
                                        </ListItemIcon> */}
                                            <Typography gutterBottom variant='subtitle1'>Navigation</Typography>
                                    </ListItem>
                                    <Divider  />
                                </List>
                                <List className={classes.space}>
                                    <div className={classes.position}>
                                    <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                        <ListItemLink exact='true' to="/">
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                               Home
                                            </Typography>
                                        </ListItemLink>
                                    </ListItem>
                                    <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                        <ListItemLink to="/about">
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                                About Us
                                            </Typography>
                                        </ListItemLink>
                                    </ListItem>
                                    <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                        <ListItemLink to="/contactus">
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                               Contact Us
                                            </Typography>
                                        </ListItemLink>
                                        </ListItem>
                                        <ListItem>
                                              <ListItemIcon>
                                                  <ChevronRightTwoToneIcon />
                                               </ListItemIcon>
                                            <ListItemLink to="/career">
                                                <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                                     Careers
                                                </Typography>
                                        </ListItemLink>
                                        </ListItem>
                                    </div>
                                </List>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} container >
                            <Grid item xs style={{justfyContent:'center', alignItems:'center'}}>
                                
                                 <List>
                                    <ListItem>
                                        <Typography gutterBottom variant='subtitle1'>Explore us</Typography>
                                            </ListItem>
                                     <Divider className={classes.position2} />
                                </List>
                            
                                <List className={classes.space}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FacebookIcon />
                                        </ListItemIcon>
                                            <ListItemUrl target="_blank" href="https://www.facebook.com/ABconsol-Projects-Private-Limited-101727598840623/?ti=as" >
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary" >
                                                facebook
                                            </Typography>
                                        </ListItemUrl>
                                    </ListItem>
                                    <ListItem>
                                    <ListItemIcon>
                                            <InstagramIcon />
                                        </ListItemIcon>
                                        <ListItemUrl target="_blank" href="https://instagram.com/abconsol?utm_medium=copy_link" >
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                                instagram
                                            </Typography>
                                        </ListItemUrl>
                                        </ListItem>
                                        <ListItem>
                                        <ListItemIcon>
                                            <LinkedInIcon />
                                        </ListItemIcon>
                                        <ListItemUrl target="_blank" href="https://www.linkedin.com/in/ab-consol-1a8046219">
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                                linkedin
                                            </Typography>
                                        </ListItemUrl>
                                        </ListItem>
                                        <ListItem>
                                        <ListItemIcon>
                                            <WhatsAppIcon />
                                        </ListItemIcon>
                                        <ListItemUrl target="_blank" href="https://wa.me/918480306236">
                                            <Typography gutterBottom variant='subtitle2' color="textSecondary">
                                                whatsapp
                                            </Typography>
                                        </ListItemUrl>
                                        </ListItem>
                                </List>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} container >
                        <Grid item xs style={{justfyContent:'center', alignItems:'center'}}>
                                 <List >
                                          <ListItem>
                                            <Typography gutterBottom variant='subtitle1'>Supplier portal</Typography>
                                         </ListItem>
                                    <Divider className={classes.position2} />
                                </List>
                                <List className={classes.space}>
                                    <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                        <ListItemUrl target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe6OcQNmkwioORgCgZYDIJOidNBlpmSUOF8AyRfbOzM0PKrVQ/viewform" >
                                            <Typography gutterBottom variant='caption' color="textSecondary" >
                                                Vendor registration
                                            </Typography>
                                        </ListItemUrl>
                                    </ListItem>
                                    <ListItem>
                                    <ListItemIcon>
                                      <ChevronRightTwoToneIcon />
                                    </ListItemIcon>
                                        <ListItemUrl target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScVRQYMnw2sLjNddOo7-eIPBA3tH-iM2l7_a5ZoWCO3NPIgKg/viewform">
                                            <Typography gutterBottom variant='caption' color="textSecondary">
                                                Sub-contractor registration
                                            </Typography>
                                        </ListItemUrl>
                                    </ListItem>
                                    {/* <ListItem>
                                        <ListItemText>
                                            <Typography gutterBottom variant='caption' color="textSecondary">
                                                <Link href="#" color='inherit'>
                                                
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>
                                                <Typography gutterBottom variant='caption' color="textSecondary">
                                                    <Link href="#" color='inherit'>
                                                    
                                                    </Link>
                                                </Typography>
                                        </ListItemText>
                                        </ListItem> */}
                                </List>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>
                <Grid item>
                <Divider className={classes.position2} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sm container>
                        <Grid item xs container direction='row' spacing={2}>
                             <Grid item xs style={{transform:'scale(0.9)'}}>
                                 <List>
                                     <ListItem>
                                         
                                     <ListItemIcon>
                                             <CopyrightIcon size='small' />
                                         </ListItemIcon>
                                     <ListItemText secondary=''>
                                            <Typography variant='overline' color="textSecondary" >
                                                ABCONSOL PROJECTS PRIVATE LIMITED  
                                            </Typography>
                                            <Typography variant='overline' color="textSecondary" style={{paddingLeft:'20px'}}>
                                                
                                                2021  All rights reserved 
                                            </Typography>
                                         </ListItemText>
                                        
                                         {/* <ListItemText secondary=''>
                                            <Typography variant='overline' color="textSecondary" >
                                                2021  All rights reserved 
                                            </Typography>
                                         </ListItemText> */}
                                     </ListItem>
                                 </List>
                            </Grid>
                            <Grid item xs style={{display:'flex',
                                                    transform:'scale(0.9)',
                                                        justifyContent:'right',}}>
                            <List>
                                     <ListItem>
                                         <ListItemIcon>
                                             <BrushIcon  size='small'/>
                                         </ListItemIcon>
                                         <ListItemText>
                                            <Typography variant='overline' color="textSecondary" >
                                                <DesignerMenu />
                                            </Typography>
                                         </ListItemText>
                                     </ListItem>

                                 </List>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </Paper>
        
    </div>
    )
}

import React from 'react';
import Button from '@material-ui/core/Button';
import  { Menu, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel'

export default function ServiceMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
    } ;

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
                <Button aria-controls="book-service" aria-haspopup="true" onClick = {handleClick} size="large"
                        style={{
                                 
                                background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
                                color:"white",
                                paddingLeft:30,
                                paddingRight:30,
                                borderRadius:50,
                                boder:'5px solid black',
                                textShadow:'2px 2px 4px #000 ',
                                transition:'0.3s',
                            
                        }}
                            
                        

                        >
                    Book service
                </Button>

                     <Menu 
                        id="service-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                            <MenuItem onClick={handleClose}
                                    style={{ background:'white',
                                                color:"black",
                                                border:'2px solid #000000',
                                                    transform:'scale(0.96)',
                                                borderRadius:20,
                                                 }}
                                                 >
                                    <CancelIcon size="large" style={{paddingRight:5,}} />
                                    Service Form
                                    
                            </MenuItem>
                            <MenuItem>{props.form}</MenuItem>
                        </Menu>
        </div>
        )
}
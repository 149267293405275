import React, {useRef} from 'react';
import {styled,  makeStyles} from '@material-ui/core/styles';
import { palette, spacing, compose } from '@material-ui/system';
import CssBaseLine from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import WithNavigation from '../navigation__bar/AppBarWithNavigation';
import ListTtemLink from '../footer/TableLine';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Gforms from './body/form';

const useStyles = makeStyles((theme) => ({
    root:{
      flexGrow:1,
      backgroundColor: '#f5f5f5',   //'#ffff8d',       //'linear-gradient(0deg, #6de6fc 19%, #e6ee9c  60%)',  //'linear-gradient(236deg, #ffeb3b 65%, #10d9f8 60%)', #ffeb3b
      //backgroundImage:`url(${image})`,
    },
    control:{
      padding:theme.spacing(1),
      marginTop:theme.spacing(7),
    },
  }));
  
  
  const Mox = styled('div')(compose(spacing, palette));

export default function CareerComponent () {
    const classes = useStyles();

    return(
      <React.Fragment>

                
        <CssBaseLine />
       
       
        
        
       <Mox className={classes.root}>
         

       <WithNavigation />
       
       
       <Container maxWidth="xl"  className={classes.control}>
       
      
                    <Grid container spacing={3} > 
                    
                     <Grid item xs={12}> 
                   </Grid>
                   {/* < Text /> */}
                   <Paper elevation={10} style={{  paddingTop:5,
                                                    margin:'auto',
                                                   background: 'linear-gradient(0deg,  #fb8c00 43%, #ffffff 100%)',
                                                   transform:'scale(0.97)'}}>  
                     <Grid item xs={12} sm={12} md={12} >
                          <Gforms />
                        </Grid>
                    </Paper> 
                                       
                        <Grid item xs={12} sm={12} md={12} >
                                       {/* Footer */}
                            <ListTtemLink />
                              </Grid> 
                          <Grid>
                    </Grid>
               </Grid>
               </Container>
       </Mox>
       
       </React.Fragment>
     );
 }
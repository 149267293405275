import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import image_avt_2nd from './../../icon/card_icon/image_avt_2nd.png';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

const useStyles =makeStyles((theme) => ({
  root: {
      background: ' ', //linear-gradient(270deg, #29b6f6 40%, #f9a825 100%)',       
      border: '0.5px solid white',
      borderColor:'white',
      transition:'0.3s',
      transform:"scale(0.95)",
      borderBottom:'5px solid black ', //#ffc107
      
    
  },
  details: {
    display:"flex",
    flexDirection:"column",
    transform:"scale(0.9)",
    '&:hover': {
      //transform:"scale(0.99)",
      transition:'0.3s',
    }
  },
  content:{
    //paddingTop:50,
    justify:'space-around',
    
  },
  
  media: {
      // justifyContent:'space-around',
      //paddingLeft:150,
      transform:'scale(0.9)',
    //direction:'row',
    // justifyContent:'center',
    // alignItems:'center',
    margin:10,

    width:150,
    height:150,
   
  },
  
expand: {
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
  duration: theme.transitions.duration.shortest,
    
  }),
},
expandOpen: {
  transform: 'rotate(180deg)',

},
heading: {
         //background:'inherit',
         textAlign:'left', 
  },

}
));



export default function ImgMediaCardMsg1(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
    
  return (
    <Card className={classes.root} elevation={10}>

        {/*  */}

          <Grid container spacing={1}>
                    <Grid item sm={12} sm container >
                        <Grid item container direction='row' spacing={0}  >
                            <Grid item xs={3} sm={3} md={3}  style={{ borderBottom:'5px solid #ffc107',}}>
                            <CardMedia
                                      className={classes.media}
                                        
                                        image={image_avt_2nd}
                                        title="logo icon"
                                      />
                         
                       </Grid >
                        <Grid item xs={12} sm={12} md={9} style={{borderBottom:'5px solid #000000 ',}}>  {/* #ffc107 */}
                            <CardHeader

                            title="Managing director's Message"
                            subheader={<Typography style={{color: "white", textShadow:'2px 2px 4px #000 '}}>Mr. Dibya ranjan Salangi</Typography>}
                            style={{    
                                      display:'flex',
                                      //width:520,
                                      height:175,
                                      color: "white",
                                      
                                      border:'1px solid white',
                                      background:'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',
                                      
                                      textShadow:'2px 2px 4px #000 ',   
                                                      }}
                                  />
                       </Grid >
                       <Grid item md={12} >
                       <CardContent >
                        <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                            This organization has been built by a committed team on a solid foundation of quality and customer trust.
                            It has passed through significant stages from its beginning, growth, and expansion.
                            During the time in the field of construction, it has successfully completed more than more than hundred major projects.
                            Justifying the company’s penchant for undertaking challenging projects. 
                          </Typography>
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <CardContent >
                            {/* <Typography paragraph>Method:</Typography> */}
                            <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                              It has also successfully extended its geographic reach .
                              The expertise has made the company capable of executing
                              civil engineering projects in the fields of marine sector, power sector, residential
                              and commercial complexes. In addition to the above, the company also has the ability to execute
                              complex structures and integration of electrical systems.
                            </Typography>
                            <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                                  We are quiet optimistic about the years ahead. I can proudly can say that we can design and build any project,
                                  however challenging and scale it may be, without cost
                                  over-run and while complying with highest standards of quality and workmanship.
                                  While proudly underlining the zero arbitration record maintained throughout, we look forward to associating with 
                                  our valued customers for mutual benefit and also to 
                                  serve the nation with the best of our ability.
                            </Typography>
                          </CardContent>
                        </Collapse>
                        <IconButton  className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded,
                                    })}
                                  
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more" >
                            <ExpandMoreIcon />
                                
                              </IconButton >
                          </CardContent>
                       </Grid>
                    </Grid>
                </Grid>
              </Grid>
      
    </Card>
  );
}

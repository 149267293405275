import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import {useSpring, animated, config } from 'react-spring';

// import { Divider, ThemeProvider } from '@material-ui/core';
// import Image2 from './icon/img/212.jpg';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

import conVideo from './../icon/video/Construction-49764.mp4';
import Hidden from '@material-ui/core/Hidden';


const useStyles = makeStyles({
  root: {
     background: 'linear-gradient(25deg, #000000  25%,#f5f5f5 25%)',//'#f5f5f5'   #f9a825          ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
      display: 'flex',
      width:'auto',
      //backgroundImage:`url(${Image2})`,
        border: '10px solid white',
        //height:'320px',
      //borderColor:'black',
      //borderRadius:10,
      //margin:20,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',
      transform:'scale(0.98)',
        '& > svg ':
         {
             margin : 0,
             
             color:"black",
         },
        
  },
  details: {
    display:"flex",
    flexDirection:"column",
  },
  content:{
     //borderRadius:50,
    //background: 'linear-gradient(300deg, #29b6f6 20%, #ffe57f 100%)', //
      margin:85,
   // Padding:1000,
    padding:20,

    alignContent:'center',
    //flex:'1 0 auto',
    //textAlign:'Lef',
    color: 'white',
    //textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

   // width:'600px',
    //height:'320px',        //'620px',
    transform:'scale(1)',
    transform:'scale(0.95)'
    // '&:hover':{
    //       textShadow:'2px 2px 4px #000 ',
    //        transform:'scale(1.1)',
    //        transition:'0.3s',
          //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
   // }
  },
  media: {
    //objectFit:'scale-up',
    borderLeft:'5px solid white',
    //background:'inherit',
    objectFit:'cover',
    // position:'absolute',
    //top:'50%',
    //left:'50%',
    // width:'300px',
    // height:'auto',
    width:'100%',
    height:'650px',
    //zIndex:"-60",
    //transform:'scale(0.99)',
    },
    text:{
        padding:10,
       //color:'white',
        color:'black',
        textShadow:'0px 0px 0px #fff ' ,
        
    },
   clipper:{
      backgroundColor:'primary',
      heigth:"50px",
      width:"50px",
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  divide:{
    width:'fit-content',
    // paddingRight:50,
    border: '1px solid white ' ,
    height:620,
    borderRadius: 0,
    //boxShadow:'0px 0px 5px 2px rgba(109,230,252)'
  }

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function ImgMediaCard() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1)', transition:' transform 0.5s',},
    from: {y:-50,z:0, opacity:0 , transform:'scale(1)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={10} >
      
      
      <Grid container spacing={2}>
       <Grid item container>
       <Grid item xs={6} sm={6} md={6} >
       
        
        <Paper elevation={5} style={{    transform:'scale(0.95)',
                                          padding:20,
                                          width:"auto",
                                          opacity:0.95,
                                          borderRadius:10, 
                                          background: 'linear-gradient(90deg, #ffffff  1%, #fb8c00  100%)', //'linear-gradient(0deg, #ffffff  60%,#e0e0e0 100%)',
                                         }}>
        
         
        {/* // style={{transform:'scale(0.98)', }}  */}
          
         
        
        <CardContent className={classes.content}>
        <animated.div style={props}>
        <Paper  elevation={15} style={{//transform:'scale(0.9)',
                                          //padding:0,
                                          //height:'270px', 
                                         // background:'linear-gradient(0deg, #ef6c00 10%, #ffa726  100%)' ,
                                         borderRadius:20, 
                                         opacity:1,
                                          background:'linear-gradient(0deg, #ffa726 10%, #ffffff 300%)' ,
                                         // borderBottom:'20px solid #000000',
                                           border:'2px solid #ffffff',
                                         // clipPath: 'circle(61% at 51% 55%)',
                                           // clipPath: 'polygon(58% at 51% 50%)',
                                        //  transform:'translate(50px, -30px)',

                                          }}>
           
        
           
           <div style={{margin:0,padding:20, //background:'linear-gradient(180deg, #fb8c00 10%, #ffffff  10%)',       //#ffc107'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',
                                  // borderTop:'20px solid #000000',
                               //   borderBottom:'20px solid #f9a825',
                                  
                                   transform:'translate(0px, -5px)',
                                 // borderRadius:50, 
                         
                        }}>

           <Typography variant="h3" component="h1"  className={classes.text}>
                 We Construct to Captivate the Future
           </Typography>
           </div>
           
           <div style={{padding:15,
                                  //background:'linear-gradient(0deg, #fb8c00 10%, #ffffff  10%)'   ,//'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)' , //'black',
                                 // transform:'scale(0.9)',
                                  // borderLeft:'20px solid #f9a825',
                                  // borderBottom:'20px solid #000000',
                                  // borderRadius:50,
                                  transform:'translate(0px, -20px)'
                                   }}>
           <Typography variant="h6" component="h1" style={{margin:10, color:'black',
                                                                                textShadow:'0px 0px 0px #fff ' ,
                                                                                textAlign:'center',
                                                                                //paddingLeft:15
                                                                                }}>
             We Are Dedicated To Ideal Quality And Excellent Design With Certified Engineers And
              Creative Professionals.
            
          </Typography>
          </div>
          
          </Paper>
          </animated.div>
          {/* <Button size="large"  variant="outlined" className={classes.buttons} >Start Here</Button> */}
        </CardContent>
        
      </Paper>
      </Grid>
      <Grid item xs={6} sm={6} md={6} >
      {/* <Grid item md={}> */}
     
         <div>
      <CardMedia
          className={classes.media}
          loop='on'
          autoPlay
          component="video"
          
            // src={Image2}
          src={conVideo}
          title="construction-img"
        >
          
        </CardMedia>
        </div>
        </Grid>
        </Grid>
       </Grid>
    </Card>
  );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import  { Menu, MenuItem, Typography } from '@material-ui/core';
import {
        BrowserRouter as Router,
        Route,
        Link
       } from 'react-router-dom';

export default function DesignerMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
    } ;

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
                <Button aria-controls="book-service" aria-haspopup="true" onClick = {handleClick} size="large">
                     <Typography variant='overline' color="textSecondary" style={{paddingLeft:'20px'}}>
                              Designed By - Enigmatic Sculptors
                     </Typography>
                </Button>

                     <Menu 
                        id="service-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                            <MenuItem component={"a"} href="https://www.linkedin.com/in/jashabanta-das215">
                                Jashabanta Das
                            </MenuItem>
                            <MenuItem component={"a"} href="https://www.linkedin.com/in/subhadeepta-tripathy-9933b2131/">
                                     Subhadeepta Tripathy
                            </MenuItem>
                        </Menu>
        </div>
        )
}
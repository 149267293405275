// import React, {useLayoutEffect} from 'react';
// import { useLocation } from 'react-router-dom';
// import {history} from 'history';
// import {BrowserRouter as Router , Route, Switch} from 'react-router-dom';

// import StyledComponents from './homePage/homeComponent';
// import aboutComponents from './aboutUs/aboutPage';
// import CareerComponent from './careers/careerPage';
// import ContactUsComponent from './contactUs/contactUs';

// import ImgMediaCard from './aboutUs/header/frontCard';

// export default function ScrollToTop({history, children}) {
   
//     const location = useLocation();

//     useLayoutEffect(() => {
//         window.scrollTo(0, 0);
//     }, [location.pathname]);

//     return(
//         <div>
//          <Switch>    
//              <Route exact path="/" component={StyledComponents} />
//                 <Route exact path="/career" component={CareerComponent} />
//                 <Route exact path="/contactus" component={ContactUsComponent} />
//                 <Route exact path="/about" component={aboutComponents} />
//                 {/* <Route exact path="/about" component={ImgMediaCard} /> */}
//          </Switch>
//       </div>
//         )
//     }

import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
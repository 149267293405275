import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import image_avt_2nd from './../../icon/card_icon/image_avt_2nd.png';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
      background: ' white', //linear-gradient(270deg, #29b6f6 40%, #f9a825 100%)',       
      border: '0.5px solid white',
      //borderColor:'white',
      transition:'0.3s',
      transform:"scale(0.95)",
      borderBottom:'5px solid black ', //#ffc107
      
    
  },
  details: {
    display:"flex",
    flexDirection:"column",
    transform:"scale(0.9)",
    '&:hover': {
      //transform:"scale(0.99)",
      transition:'0.3s',
    }
  },
  content:{
    //paddingTop:50,
    justify:'space-around',
    
  },
  
  media: {
      justifyContent:'space-around',
      paddingLeft:150,
      transform:'scale(0.9)',
    //direction:'row',
    justify:'center',
    alignItems:'center',
    margin:10,

    width:150,
    height:150,
   
  },
  
expand: {
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
  duration: theme.transitions.duration.shortest,
    
  }),
},
expandOpen: {
  transform: 'rotate(180deg)',

},
heading: {
         //background:'inherit',
         textAlign:'left', 
  },

}
));



export default function ImgMediaCardMsg(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
    
  return (
    <div>
    <Card className={classes.root} elevation={10}>

        {/*  */}

          <Grid container spacing={1}>
                    <Grid item xs={12} sm container >
                        <Grid item container direction='row' spacing={0}  >
                            <Grid item xs={3} sm={3} md={3}  style={{
                                //padding:10,
                                alignItems:'center',
                                display:'flex',
                               borderBottom:'5px solid #ffc107',}}>
                                 {/* <Paper></Paper> */}
                            <CardMedia
                                      className={classes.media}
                                        
                                        image={image_avt_2nd}
                                        title="logo icon"
                                      />
                                      {/* <Avatar alt="Chairman" style={{ 
                                                                      height: 120,
                                                                      width:120,
                                                                      backgroundColor: deepPurple[500]}}>CH</Avatar> */}
                         
                       </Grid >
                        <Grid item xs={12} sm={12} md={9} style={{borderBottom:'5px solid #000000 ',}}>  {/* #ffc107 */}
                            <CardHeader

                            title="Chairman's Message"
                            subheader={<Typography style={{color: "white", textShadow:'2px 2px 4px #000 '}}>Mrs. Arati Salangi</Typography>}
                            style={{    
                                      display:'flex',
                                      //width:520,
                                      height:175,
                                      color: "white",
                                      
                                      border:'1px solid white',
                                      background:'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)',
                                      
                                      textShadow:'2px 2px 4px #000 ',   
                                                      }}
                                  />
                       </Grid >
                       <Grid item md={12} >
                       <CardContent >
                        <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                                Welcome to ABCONSOL PVT LTD, built on the strength of 
                                a strong and highly experienced management team. Our strength is developed by our unique ideas and values,
                                where we develop excellent and smart services by giving equal importance to all stakeholders involved in 
                                the chain, such as our diverse talented work team, partners, clients, and the society.
                          </Typography>
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent >
                              {/* <Typography paragraph>Method:</Typography> */}
                              <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                                  ABCONSOL PVT LTD stands
                                  for engineering sense solutions. Which is exactly what the company wants to do. We take pride on ourselves on constantly
                                    improving our technical knowledge and skills in a rapidly changing industry.
                                    We work in versus fields like the power sector, Civil and Construction. We are mostly clear about our work and
                                      responsibility, team work which is about quality.
                              </Typography>
                              <Typography className={classes.heading} variant='subtitle2'  gutterBottom paragraph>
                                        Our journey has been possible because of the blessing and good wishes of all our employees,   
                                        So safety plays a major role in our organization. We always assure our skill development program because a
                                        skill make employ to reach perfection.
                                        We will continue to evolve, transform and grow and while we do, we hope to have your continued 
                                        support and good wishes.
                              </Typography>
                            </CardContent>
                          </Collapse>
                        <IconButton  className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded,
                                    })}
                                  
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more">
                            <ExpandMoreIcon />
                                
                              </IconButton >
                          </CardContent>
                       </Grid>
                    </Grid>
                </Grid>
              </Grid>
       
    </Card>
    </div>
  );
}

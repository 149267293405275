
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import icon_card2 from './../../icon/img/transmission-mini.png';
import ServiceMenu from './bookService';

const useStyles = makeStyles((theme) => ({
  root: {
      background: 'white', //,'linear-gradient(0deg, #ffc107 1%, #fb8c00 100%)'
    //maxWidth: 345,
    border:'3px solid white',
    transform:"scale(0.9)",

    borderBottom: '10px solid #ffc107',
  },
  media: {
    height: 0,
    transition:'0.3s',
    paddingTop: '56.25%', // 16:9
    '&:hover': {
                        //backgroundColor: "#ffc107",// #fff9c4
                        transform:"scale(1.2)",
                        transition:'0.3s',
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      
    }),
  },
  expandOpen: {
    transform: 'scale(1.1)',

  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MediaCard1() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} elevation={10}>
      
      <CardMedia

        

        className={classes.media}
        image={icon_card2}
        title="Energy Infrastructure"
      />
      {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        ></IconButton> 
        */}

      <CardHeader
        
        action={
            <IconButton disabled={true}>
            <ExpandMoreIcon style={{
                paddingTop:10,
                color: "white"}} />
            </IconButton>
        }

        title="Energy Infrastructure"
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
            style={{     color: "white",
                        border:'1px solid white',
                        background:'#000000',
                        //background:'#b66524',
                        textShadow:'2px 2px 4px #000 ',    
                         }}
                            />
      
      
     {/* <CardContent>
         <Typography variant="body2" color="textSecondary" component="p">
          This impressive paella is a perfect party dish and a fun meal to cook together with your
          guests. Add 1 cup of frozen peas along with the mussels, if you like.
        </Typography>
      </CardContent>
     
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
         {/* <CardActions disableSpacing></CardActions> */}
        
        
          {/* <ExpandMoreIcon /> */}
        
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph>We offreed service for:</Typography>  */}
          <Typography paragraph style={{textAlign:'left'}}>
                We are providing professional services throughout the project lifecycle. 
                From power distribution, Compact Substitution and 
                Transmission Line from construction to
                management and  maintenance of energy infractures.
                We also work for various government and private projects of energy infractures.
          </Typography>
          <ServiceMenu form={<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdzx3MTMk0NJyKh7z7FoEECCXPEh2DjBfg0ydZfvR0GEvZpPw/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
} />
          {/* <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography> */}
          {/* <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography> */}
          {/* <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import icon_card4 from './../icon/img/front-img3.png';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Text from './fontcardAnimation';

import {useSpring, animated, config } from 'react-spring';

// import { Divider, ThemeProvider } from '@material-ui/core';
// import Image2 from './icon/img/212.jpg';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

//import conVideo from './../icon/video/Construction-49764.mp4';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  root: {
    // background:     'linear-gradient(0deg, #000000  1%,#f9a825 50%)',//'#f5f5f5'             ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
      display: 'flex',
      width:'auto',
      height:'auto',
      minHeight:'520px',
      transform:'scale(0.9)',
      //backgroundImage:`url(${Image2})`,
        border: '10px solid white',
        //height:'420px',
      //borderColor:'black',
      //borderRadius:10,
        margin:20,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',

      transform:'scale(1.1)',
        
  },
  details: {
    display:"flex",
    flexDirection:"column",
  },

  // content:{
  //   color: 'white',
  //   textShadow:'2px 2px 4px #000 ',
  //   transition:'0.3s',

  //   //  width:'600px',
  //   height:0,        //'620px',
  //   transform:'scale(1)',
  //   transform:'scale(0.95)'
  //   // '&:hover':{
  //   //       textShadow:'2px 2px 4px #000 ',
  //   //        transform:'scale(1.1)',
  //   //        transition:'0.3s',
  //         //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
  //  // }
  // },

  media: {
    paddingTop: '56.25%',
    //objectFit:'scale-down',
    borderLeft:'5px solid white',
   // background:'inherit',
    objectFit:'cover',
  
      width:'100%',
       height:'auto',
    //zIndex:"-30",
    //transform:'scale(0.99)',
    },
    text:{
        padding:10,
        background:'white',
        color:'black',
        //textShadow:'2px 2px 4px #fff ' ,
        
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function ImgmediaCard2() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1.3)', transition:' transform 0.5s',},
    from: {y:-30,z:0, opacity:0 , transform:'scale(1.3)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={10} >
      
      
      {/* <Grid container spacing={0} >
                    <Grid item xs={12} sm container>
                        <Grid item xs={12} sm={12} md={12} container direction='column' spacing={0}>
                          <Grid item xs> */}
                          <CardMedia
                                className={classes.media}
                               // loop='on'
                             //   autoPlay
                                component="image"
                                
                                image={icon_card4}
                                title="construction-img"
                              >
      
                          {/* </Grid>
                            <Grid item xs > */}
                                

                            <CardContent className={classes.content}>


                            <Paper elevation={15} style={{  // margin:100,
                
                                         opacity:0.9,
                                         // borderRadius:10, 
                                          background:'black',
                                          transform:'translate(0px,-190px)',
                                         
                                         }}>

        
        
        <Paper  elevation={15} style={{   margin:0,
                                          padding:0,
                                          transform:'scale(1.3)',

                                             height:'150px', 
                                             background: 'transparent',                //'#ffb74d',
                                              borderBottom:'20px solid #f57c00',
                                         
                                         //   clipPath: 'circle(55% at 50% 50%)',
                                         //   clipPath: 'polygon(58% at 51% 50%)',

                                              transform:'translate(0px,0px)',

                                          }}>
           
        
           <animated.div style={props}></animated.div>
           <div style={{margin:0,padding:0, 
                                        background: 'transparent',    //'linear-gradient(180deg, #fb8c00 10%, #ffffff  10%)',       //#ffc107'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',
                               //    borderTop:'20px solid #f57c00',
                                //     borderBottom:'20px solid #f57c00',
                                  transform:'translate(0px, 0px)',
                                 borderRadius:50, 
                         
                        }}>

           <Typography variant="h4" component="h2"  className={classes.text}>
                 We Construct to Captivate the Future
           </Typography>
           </div>
           
           <div style={{padding:15,
                                  background:'#fb8c00'   ,//'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)' , //'black',
                                  transform:'scale(0.9)',
                                   borderTop:'20px solid black',
                                  // borderBottom:'20px solid #f57c00',
                                  // borderRadius:50,
                                 transform:'translate(0px, 0px)',
                                      // opacity:0.5',
                                   }}>
           <Typography variant="subtitle1" component="p" style={{margin:30, color:'white',
                                                                               // textShadow:'2px 2px 4px #fff ' ,
                                                                                textAlign:'center',
                                                                               // paddingLeft:15
                                                                                }}>
             We Are Dedicated To Ideal Quality And Excellent Design With Certified Engineers And
              Creative Professionals.
            
          </Typography>
          </div>
          
          </Paper>
          {/* <Button size="large"  variant="outlined" className={classes.buttons} >Start Here</Button> */}
      
        
            </Paper>
                </ CardContent>
                </CardMedia>
                     {/* </ Grid>
                 </Grid>
              </Grid>
              </ Grid> */}
     
      
    </Card>
  );
}

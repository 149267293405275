import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Text from './fontcardAnimation';

import {useSpring, animated, config } from 'react-spring';

// import { Divider, ThemeProvider } from '@material-ui/core';


import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

import Img from './../img/cubes.png';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  root: {
     background: 'linear-gradient(0deg, #ffc107 1%, #fb8c00 110%)' , //   #f9a825          ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
     // display: 'flex',
      //width:'auto',
      //backgroundImage:`url(${Image2})`,
        // border: '1px solid white',
        // flex:'0 0 auto',
        //height:'320px',
      //borderColor:'black',
      //borderRadius:10,
      //margin:20,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',
      marginTop:'10px',
        transform:'scale(0.97)',
        '& > svg ':
         {
             margin : 0,
             
             color:"black",
         },
        
  },
  details: {
    display:"flex",
    flexDirection:"column",
  },
  content:{
     //borderRadius:50,
    //background: 'linear-gradient(300deg, #29b6f6 20%, #ffe57f 100%)', //
    //margin:130,
   // PaddingLeft:100,
   // paddingTop:180,

    //justify:'center',
    
    //textAlign:'Lef',
    color: 'white',
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    //  width:'300px',
    // height:'320px',        //'620px',
    transform:'scale(1)',
    transform:'scale(0.95)'
    // '&:hover':{
    //       textShadow:'2px 2px 4px #000 ',
    //        transform:'scale(1.1)',
    //        transition:'0.3s',
          //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
   // }
  },
  media: {
   // objectFit:'scale-down',
   // borderLeft:'5px solid white',
   // background:'inherit',
   //justifyContent:'center',
    objectFit:'cover',
   // clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%, 15% 0%)',
     //width:'925px',
    // height:'auto',
    //width:'100%',
    height:'450px',
    //zIndex:"-60",
    //transform:'scale(0.90)',
    },
    text:{
        padding:10,
       // color:'white',
        color:'white',
        //textShadow:'2px 2px 2px #fff ' ,
        
    },
   clipper:{
      backgroundColor:'primary',
      heigth:"50px",
      width:"50px",
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  divide:{
    width:'fit-content',
    // paddingRight:50,
    border: '1px solid white ' ,
    height:620,
    borderRadius: 0,
    //boxShadow:'0px 0px 5px 2px rgba(109,230,252)'
  }

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function MediaCard3() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1.3)', transition:' transform 0.5s',},
    from: {y:-30,z:0, opacity:0 , transform:'scale(1.3)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={10} >
      
        <Grid container spacing={2}>
       <Grid item xs={12}  sm container>
          <Grid item xs={12} sm={12} md={6} >
       
      <CardMedia
          className={classes.media}
         // loop='on'
         // autoPlay
          //component="video"
          
            // src={Image2}
          image={Img}
          title="aboutUs-img"
        >  
        </CardMedia>

        </Grid>
        <Grid item xs={12} sm={12} md={6}>
        {/* <Divider orientation="vertical" /> */}
        <Paper elevation={10} style={{   // transform:'scale(1)',
                                           // width:'700px',
                                          opacity:0.95,
                                          //borderRadius:10, 
                                          //clipPath: 'polygon(100% 0%, 90% 90%, 0% 90%, 0% 0%)',
                                          background: '#fb8c00', //linear-gradient(90deg, #ffffff  1%, #fb8c00  100%)', //'linear-gradient(0deg, #ffffff  60%,#e0e0e0 100%)',
                                         }}>
        
        
        {/* // style={{transform:'scale(0.98)', }}  */}
          
        
        
        <CardContent className={classes.content}>
            
        
          <div>
          <Paper style={{ width:'300px' ,height:'60px', padding:5,background:'#000000',transform:'translate(0px, -10px)'}} >
                                              <Typography variant="h5" style={{color:'white',padding:6 }}>
                                                  Why Choose Us
                                              </Typography>
                                              </Paper>
        <Paper  elevation={12} style={{//transform:'scale(1)',
                                          display:'flex',
                                          // height:'270px',
                                          // width:'730px' ,
                                         // background:'white' ,
                                         // borderBottom:'20px solid #000000',
                                         //border:'2px solid #ffffff',
                                        // clipPath: 'circle(61% at 51% 55%)',
                                          //  clipPath: 'polygon(70% 30%, 100% 70%, 0% 70%, 0% 30%)',
                                          //transform:'translate(0px, -170px)',

                                          }}>
        
           {/* <animated.div style={props}></animated.div> */}
           
             
           {/* <div style={{margin:0,padding:0, //background:'linear-gradient(180deg, #fb8c00 10%, #ffffff  10%)',       //#ffc107'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',
                                  // borderTop:'20px solid #000000',
                               //   borderBottom:'20px solid #f9a825',
                                    transform:'translate(0px, 70px)',
                                 // borderRadius:50, 
                         
                        }}></div> */}

           {/* <Typography variant="subtitle1" component="h2"  className={classes.text}>
                 We Construct to Captivate the Future
           </Typography> */}
           
           
           <div style={{padding:15,
                                  //background:'linear-gradient(0deg, #fb8c00 10%, #ffffff  10%)'   ,//'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)' , //'black',
                                  transform:'scale(1.2)',
                                  // borderLeft:'20px solid #f9a825',
                                  // borderBottom:'20px solid #000000',
                                  // borderRadius:50,
                                  transform:'translate(0px, 0px)'
                                   }}>
            <Typography variant="subtitle1" component="h1" style={{margin:10, color:'black',
                                                                                padding:10,
                                                                                textShadow:'0px 0px 0px #fff ' ,
                                                                                textAlign:'left',
                                                                                paddingLeft:0}}>
             We are specialised mainly in with road, highways, buildings and factories, water and irrigation with 
             good quality and service as a part of our infrastructure development and construction through our subsidiaries.
              At every detail of a project from commissioning to preparation in a way that smooths out a positive building experience.
               Through our own on-site work (concrete, masonry, carpentry, exterior and interior finishing), we have better control
                over the project process and accelerate the project. All of our construction projects are carried out with modern
            technology and materials. Our company places emphasis on continuous training and the safety of our workforce, and spares 
            no expense in pursuit.

           </Typography>
          </div>
          
          </Paper>
          </div>
        </CardContent>
       
      </Paper>
      </Grid>
     
     
      </Grid>
        
        </Grid>
    </Card>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Text from './fontcardAnimation';

import {useSpring, animated, config } from 'react-spring';

// import { Divider, ThemeProvider } from '@material-ui/core';


import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';

import Img from './../img/aboutus1.png';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  root: {
     background: 'linear-gradient(130deg,  #fb8c00 1%, #ffc107 10%)' , //   #f9a825          ,//'linear-gradient(60deg, #29b6f6 40%, #ffe57f 100%)',   //#6de6fc 'linear-gradient(40deg, #29b6f6  30%,#f9a825 100%)' #ffc107   
      //display: 'flex',
     // width:'auto',
      //backgroundImage:`url(${Image2})`,
        //border: '3px solid white',
        // transform:'scale(0.97)',
        //height:'320px',
      //borderColor:'black',
      //borderRadius:10,
     // paddingTop:40,
      //boxShadow:'0px 0px 1px 1px rgba(41,253,246)',
     // marginTop:'10px',
        transform:'scale(0.97)',
        '& > svg ':
         {
             margin : 0,
             
             color:"black",
         },
        
  },
  details: {
    display:"flex",
    //flexDirection:"column",
  },
  content:{
     //borderRadius:50,
    //background: 'linear-gradient(300deg, #29b6f6 20%, #ffe57f 100%)', //
    //margin:130,
   
    
    //justify:'center',
    flex:'1 0 auto',
    //textAlign:'Lef',
    color: 'white',
    //textShadow:'2px 2px 4px #000 ',
    //transition:'0.3s',

     //width:'800px',
    //height:'600px',        //'620px',
    //transform:'scale(1)',
    //transform:'scale(0.9)'
    // '&:hover':{
    //       textShadow:'2px 2px 4px #000 ',
    //        transform:'scale(1.1)',
    //        transition:'0.3s',
          //boxShadow:'0px 1px 5px 0.5px rgba(109,230,252)'
   // }
  },
  media: {
   // objectFit:'scale-down',
   // borderLeft:'5px solid white',
   // background:'inherit',
    objectFit:'cover',
    //clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%, 15% 0%)',
    // width:'625px',
    // height:'auto',
    //width:'100%',
     height:'380px',
    //zIndex:"-60",
   // transform:'scale(0.9)',
    },
    text:{
        padding:10,
       // color:'white',
        color:'white',
        //textShadow:'2px 2px 2px #fff ' ,
        
    },
   clipper:{
      backgroundColor:'primary',
      heigth:"50px",
      width:"50px",
    },

  buttons: { 
    background: 'linear-gradient(180deg, #fb8c00 20%, #ffc107 90%)',   //"#0bbcf8",
    color:"white",
    borderRadius:50,
    textShadow:'2px 2px 4px #000 ',
    transition:'0.3s',

    '&:hover': {
      background: 'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)', //"#fb8c00",
      color:"white",
      transition:'0.3s',
      transform:"scale(1.1)",
      borderRadius:50,
      textShadow:'2px 2px 4px #000 ' 

    },

  },
  divide:{
    width:'fit-content',
    // paddingRight:50,
    border: '1px solid white ' ,
    height:620,
    borderRadius: 0,
    //boxShadow:'0px 0px 5px 2px rgba(109,230,252)'
  }

});


  //return <animated.h1 style={props}>ok ok</animated.h1>

export default function MediaCard() {
  const classes = useStyles();
  const [flip, set] = React.useState(false);
  const props = useSpring({
    // to: {x:0,z:0, transform : 'scale(0.8)', transition:'0.3s'},
    // from: {x:0,z:0, transform: 'scale(1.)' , transition:'0.3s'},
    to: {y:0,z:0, opacity:1,  transform:'scale(1.3)', transition:' transform 0.5s',},
    from: {y:-30,z:0, opacity:0 , transform:'scale(1.3)', transition:' transform 0.5s'},

    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(flip),
  });

  return (
    <Card className={classes.root} elevation={15} >
              <Grid container spacing={2}>
       <Grid item container>
       <Grid item xs={12} sm={12} md={6} >
      {/* <Hidden mdDown> */}
      <CardMedia
          className={classes.media}
         // loop='on'
         // autoPlay
          //component="video"
          
            // src={Image2}
          image={Img}
          title="aboutUs-img"
        >
          
        </CardMedia>
        </Grid>
        {/* </Hidden> */}
          <Grid item xs={12} sm={12} md={6} >
      
      {/* <Divider className={classes.divide}  orientation="vertical" /> */}
            
        
            
           {/* <CardContent style={{transform:'scale(1.2)',margin:1,}}>
           <Paper style={{  
                            background:'linear-gradient(0deg, #ef6c00 10%, #ffa726  60%)' ,
                            paddingTop:410,
                            marginTop:15,
                            transform:'scale(0.9)',
                            paddingLeft:10,
                            paddingRight:10,
                            paddingBottom:10 ,
                            // border:'2px solid #ffffff',
                            borderRadius:5,}}>
             <div>
             
            </div>
            </Paper>
        </CardContent> */}
      
        
       
        {/* <Divider orientation="vertical" /> */}
        <div>
        <Paper elevation={10} style={{  //transform:'scale(0.95)',
                                       padding:5,
                                           // width:'900px',
                                         // opacity:0.95,
                                          //borderRadius:10, 
                                         //clipPath: 'polygon(100% 0%, 95% 95%, 0% 95%, 0% 0%)',
                                          background: '#fb8c00', //linear-gradient(90deg, #ffffff  1%, #fb8c00  100%)', //'linear-gradient(0deg, #ffffff  60%,#e0e0e0 100%)',
                                         }}>
        
         
        {/* // style={{transform:'scale(0.98)', }}  */}
          
         
        
        <CardContent className={classes.content}>
            <Paper elevation={20  } style={{height:'60px',padding:10,
                                          width:'300px' ,
                                         // transform:'translate(0px, -195px)',
                                          background:'black',
                                        }} 
                                          >
                                              <Typography variant="h5" style={{color:'white',textShadow:'0px 0px 0px #fff ' ,}}>
                                                  About Company
                                              </Typography>
                                          </Paper>
        
        <Paper  elevation={15} style={{//transform:'scale(0.9)',
                                          
                                          // height:'470px',
                                          // width:'750px' ,
                                          background:'white' ,
                                         // borderBottom:'10px solid #000000',
                                         //border:'2px solid #ffffff',
                                        // clipPath: 'circle(61% at 51% 55%)',
                                          //  clipPath: 'polygon(70% 30%, 100% 70%, 0% 70%, 0% 30%)',
                                         // transform:'translate(0px, -170px)',

                                          }}>
           
        
           {/* <animated.div style={props}></animated.div> */}
           
           
           
           {/* <div style={{padding:15,
                                  //background:'linear-gradient(0deg, #fb8c00 10%, #ffffff  10%)'   ,//'linear-gradient(360deg, #ffc107 30%, #fb8c00 80%)' , //'black',
                                  transform:'scale(1)',
                                  // borderLeft:'20px solid #f9a825',
                                  // borderBottom:'20px solid #000000',
                                  // borderRadius:50,
                                 // transform:'translate(0px, 0px)'
                                   }}> */}
           <Typography variant="subtitle1" component="h1" style={{margin:10, color:'black',
                                                                                padding:10,
                                                                                textShadow:'0px 0px 0px #fff ' ,
                                                                                textAlign:'left',
                                                                                paddingLeft:0}}>
            ABCONSOL was established with a big dream of giving quality service to people with a small team of
             passionate people, we have gone through many ups and downs standing on the foundation of commitment 
             slaying true to core of quality service and commitment of on time service, Which has given us trust
              and blessings provided with opportunity to do more and more towards our goals . Our struggle
               has keept us on the edge for walk to the top with innovative approach of what we do. Which is possible of the 
               group of remarkable people. We solely focus on technology, design, creativity and innovation with 
               a touch of perfection.   
            
          </Typography>
          </Paper>
         
        </CardContent>
        
      </Paper>
     
      </div>
      
        </Grid>
        </Grid>
        </Grid>
    </Card>
  );
}

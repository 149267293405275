import React from 'react';
import './App.css';
import {BrowserRouter as Router , Route, Switch} from 'react-router-dom';
import StyledComponents from './components/homePage/homeComponent';
import aboutComponents from './components/aboutUs/aboutPage';
import CareerComponent from './components/careers/careerPage';
import ContactUsComponent from './components/contactUs/contactUs';
import ScrollToTop from './components/ScrollToTop';
import WithNavigation from './components/navigation__bar/AppBarWithNavigation';
import ListTtemLink from './components/footer/TableLine';

function App() {

  return (
    
    
    
         <ScrollToTop>
            <div className="App">
                  <Switch>
                        <Route exact path="/" component={StyledComponents} />
                        <Route path="/about" component={aboutComponents} />
                        <Route path="/career" component={CareerComponent} />
                        <Route path="/contactus" component={ContactUsComponent} />
                  </Switch>
              </div>
           </ScrollToTop>
        
   
      
    
  );
}

export default App;
